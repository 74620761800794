<template>
  <div></div>
</template>
<script>
import Cookies from "vue-cookies";
export default {
  computed: {
    referralCode() {
      return this.$route.params.referralCode;
    },
    utmCampaign() {
      return this.$route.query.utm_campaign;
    },
    utmMedium() {
      return this.$route.query.utm_medium;
    },
  },
  beforeMount() {
    this.saveCode();
  },
  methods: {
    redirectTo() {
      return this.$router.push({
        name: "register",
      });
    },
    saveCode() {
      Cookies.set("referralCode", this.referralCode, "24h");
      Cookies.set("utmCampaign", this.utmCampaign, "24h");
      Cookies.set("utmMedium", this.utmMedium, "24h");
      this.redirectTo();
    },
  },
};
</script>

